@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto+Slab:wght@100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #fad702;
  --prime-color: #ffbc06;
  --secondary-color: #ff4081;
  --background-color: #595855;
  --background-warning-color: #ffd1d1;
  --text-color: #404145;
  --color-bg1: rgb(108, 0, 162);
  --color-bg2: rgb(0, 17, 82);
  --color1: 18, 113, 255;
  --color2: 221, 74, 255;
  --color3: 100, 220, 255;
  --color4: 200, 50, 50;
  --color5: 180, 180, 50;
  --color-interactive: 140, 100, 255;
  --circle-size: 100%;
  --blending: hard-light;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: 1920px 1080px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.special-font {
  font-family: 'DomaineDisplay', 'Helvetica Neue' !important;
  font-style: normal;
  color: var(--primary-color);
}

/* .login-body {
  background-image: url('./assets/background.jpg');
  background-size: 1920px 1080px;
} */

@keyframes moveInCircle {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveVertical {
  0% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(-50%);
  }
}

@keyframes moveHorizontal {
  0% {
    transform: translateX(-50%) translateY(-10%);
  }
  50% {
    transform: translateX(50%) translateY(10%);
  }
  100% {
    transform: translateX(-50%) translateY(-10%);
  }
}

.login-wrapper {
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
}

.gradient-bg {
  width: 100vw;
  height: 100vh;
  position: absolute;
  overflow: hidden;
  background: linear-gradient(40deg, var(--color-bg1), var(--color-bg2));
  top: 0;
  left: 0;

  .gradients-container {
    filter: url(#goo) blur(40px);
    width: 100%;
    height: 100%;
  }

  .g1 {
    position: absolute;
    background: radial-gradient(
        circle at center,
        rgba(var(--color1), 0.8) 0,
        rgba(var(--color1), 0) 40%
      )
      no-repeat;
    mix-blend-mode: var(--blending);
    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);
    transform-origin: center center;
    animation: moveVertical 30s ease infinite;
    opacity: 1;
  }

  .g2 {
    position: absolute;
    background: radial-gradient(
        circle at center,
        rgba(var(--color2), 0.8) 0,
        rgba(var(--color2), 0) 40%
      )
      no-repeat;
    mix-blend-mode: var(--blending);
    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);
    transform-origin: calc(50% - 400px);
    animation: moveInCircle 20s reverse infinite;
    opacity: 1;
  }

  .g3 {
    position: absolute;
    background: radial-gradient(
        circle at center,
        rgba(var(--color3), 0.8) 0,
        rgba(var(--color3), 0) 40%
      )
      no-repeat;
    mix-blend-mode: var(--blending);
    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2 + 200px);
    left: calc(50% - var(--circle-size) / 2 - 500px);
    transform-origin: calc(50% + 400px);
    animation: moveInCircle 40s linear infinite;
    opacity: 1;
  }

  .g4 {
    position: absolute;
    background: radial-gradient(
        circle at center,
        rgba(var(--color4), 0.8) 0,
        rgba(var(--color4), 0) 50%
      )
      no-repeat;
    mix-blend-mode: var(--blending);
    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);
    transform-origin: calc(50% - 200px);
    animation: moveHorizontal 40s ease infinite;
    opacity: 0.7;
  }

  .g5 {
    position: absolute;
    background: radial-gradient(
        circle at center,
        rgba(var(--color5), 0.8) 0,
        rgba(var(--color5), 0) 40%
      )
      no-repeat;
    mix-blend-mode: var(--blending);
    width: calc(var(--circle-size) * 2);
    height: calc(var(--circle-size) * 2);
    top: calc(50% - var(--circle-size));
    left: calc(50% - var(--circle-size));
    transform-origin: calc(50% - 800px) calc(50% + 200px);
    animation: moveInCircle 20s reverse infinite;
    opacity: 1;
  }

  .interactive {
    position: absolute;
    background: radial-gradient(
        circle at center,
        rgba(var(--color-interactive), 0.8) 0,
        rgba(var(--color-interactive), 0) 40%
      )
      no-repeat;
    mix-blend-mode: var(--blending);
    width: 100%;
    height: 100%;
    top: -50%;
    left: -50%;
    opacity: 0.7;
  }
}

.max-width-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 24px 24px 48px;
  width: 100%;
}

@media only screen and (min-width: 600px) {
  .max-width-container {
    margin: 0;
    padding: 32px 32px 64px;
  }
}

@media only screen and (min-width: 1160px) {
  .max-width-container {
    margin: 0 auto;
    max-width: 1400px;
  }
}

@media only screen and (min-width: 1760px) {
  .max-width-container {
    padding-left: 0;
    padding-right: 0;
  }
}

.blur-border {
  position: relative;
}

.blur-border::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 20px;
  right: -10px;
  bottom: -5px;
  background: rgb(187 187 187 / 50%);
  filter: blur(10px);
  z-index: -1;
  border-radius: 10px;
}

/* SIDEBAR */
.sidebar {
  /* display: flex; */
  /* flex-direction: row; */
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
  height: 96vh;
  width: 250px;
  padding: 0 1.7rem;
  overflow: hidden;
  color: aliceblue;
  background-color: #2f4050;
}

.sidebar-list {
  height: auto;
  position: relative;
  padding: 0;
  width: 100%;
}

.sidebar-list .row {
  border-radius: 8px;
  list-style-type: none;
  margin: 8px 0;
  display: flex;
  flex-direction: row;
  color: white;
  padding: 1rem;
}

.sidebar-list .row:hover {
  cursor: pointer;
  background-color: #293846;
}

.sidebar-list .row a {
  text-decoration: none;
  color: inherit;
  justify-self: center;
}

.sidebar-list #active {
  background-color: #293846;
}

.container {
  width: 60%;
  height: 700px;
  margin: 0 auto;
  margin-top: rem;
  border: 1px solid #0f172a;
  padding: 24px;
}

.container {
  /* display: flex; */
  /* justify-content: space-evenly; */
  flex-wrap: wrap;
}

.form-group {
  flex-wrap: wrap;
}

/* SETTING CONTENT */

.setting-content {
  position: relative;
  width: 100%;
  padding: 1rem;
}

/* SECTION */

/* section {
  padding-top: 4vh;
  height: 96vh;
  margin: 0 10rem;
  box-sizing: border-box;
  min-height: fit-content;
}

.section-container {
  display: flex;
} */

/* PROFILE SECTION  */
/* #profile {
  display: flex;
  justify-content: center;
  gap: 5rem;
  height: 80vh;
}

.section__pic-container {
  display: flex;
  height: 400px;
  width: 400px;
  margin: auto 0;
}

.section__text {
  align-self: center;
  text-align: center;
}

.section__text p {
  font-weight: 600;
}

.section__text__p1 {
  text-align: center;
}

.section__text__p2 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

.title {
  font-size: 3rem;
  text-align: center;
}

#socials-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;
}

.avatar {
  border-radius: 2rem;
} */

/* ICONS */

.icon {
  cursor: pointer;
  height: 2rem;
}

/* ABOUT SECTION */

#about {
  position: relative;
}

.about-containers {
  gap: 2rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.about-details-container {
  justify-content: center;
  flex-direction: column;
}

.about-containers,
.about-details-container {
  display: flex;
}

.about-pic {
  border-radius: 2rem;
}

.details-container {
  padding: 1.5rem;
  flex: 1;
  background: white;
  border-radius: 2rem;
  border: rgb(53, 53, 53) 0.1rem solid;
  text-align: center;
}

.section-container {
  gap: 4rem;
}

.section__pic-container {
  height: 400px;
  width: 400px;
  margin: auto 0;
}

.slider {
  margin: 0 20px;
  overflow: 'hidden';
  padding: 2rem 0;
}

@keyframes flyInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes flyInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.fly-in-left {
  animation: flyInFromLeft 1.5s forwards;
}

.fly-in-right {
  animation: flyInFromRight 1.5s forwards;
}

.newtons-cradle {
  --uib-size: 50px;
  --uib-speed: 1.2s;
  --uib-color: #474554;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--uib-size);
  height: var(--uib-size);
}

.newtons-cradle__dot {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 25%;
  transform-origin: center top;
}

.newtons-cradle__dot::after {
  content: '';
  display: block;
  width: 100%;
  height: 25%;
  border-radius: 50%;
  background-color: var(--uib-color);
}

.newtons-cradle__dot:first-child {
  animation: swing var(--uib-speed) linear infinite;
}

.newtons-cradle__dot:last-child {
  animation: swing2 var(--uib-speed) linear infinite;
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }

  25% {
    transform: rotate(70deg);
    animation-timing-function: ease-in;
  }

  50% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }
}

@keyframes swing2 {
  0% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }

  50% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }

  75% {
    transform: rotate(-70deg);
    animation-timing-function: ease-in;
  }
}

@keyframes square-animation {
  0% {
    left: 0;
    top: 0;
  }

  10.5% {
    left: 0;
    top: 0;
  }

  12.5% {
    left: 32px;
    top: 0;
  }

  23% {
    left: 32px;
    top: 0;
  }

  25% {
    left: 64px;
    top: 0;
  }

  35.5% {
    left: 64px;
    top: 0;
  }

  37.5% {
    left: 64px;
    top: 32px;
  }

  48% {
    left: 64px;
    top: 32px;
  }

  50% {
    left: 32px;
    top: 32px;
  }

  60.5% {
    left: 32px;
    top: 32px;
  }

  62.5% {
    left: 32px;
    top: 64px;
  }

  73% {
    left: 32px;
    top: 64px;
  }

  75% {
    left: 0;
    top: 64px;
  }

  85.5% {
    left: 0;
    top: 64px;
  }

  87.5% {
    left: 0;
    top: 32px;
  }

  98% {
    left: 0;
    top: 32px;
  }

  100% {
    left: 0;
    top: 0;
  }
}

.loader {
  position: relative;
  width: 96px;
  height: 96px;
  transform: rotate(45deg);
}

.loader-square {
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  margin: 2px;
  border-radius: 0px;
  background: white;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  animation: square-animation 10s ease-in-out infinite both;
}

.loader-square:nth-of-type(0) {
  animation-delay: 0s;
}

.loader-square:nth-of-type(1) {
  animation-delay: -1.4285714286s;
}

.loader-square:nth-of-type(2) {
  animation-delay: -2.8571428571s;
}

.loader-square:nth-of-type(3) {
  animation-delay: -4.2857142857s;
}

.loader-square:nth-of-type(4) {
  animation-delay: -5.7142857143s;
}

.loader-square:nth-of-type(5) {
  animation-delay: -7.1428571429s;
}

.loader-square:nth-of-type(6) {
  animation-delay: -8.5714285714s;
}

.loader-square:nth-of-type(7) {
  animation-delay: -10s;
}

.item-wrapper {
  width: 90%;
  max-width: 1536px;
  margin-inline: auto;
  position: relative;
  height: 200px;
  margin-top: 3rem;
  overflow: hidden;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0)
  );
}

@keyframes scrollLeft {
  to {
    left: -300px;
  }
}

.item {
  width: 300px;
  height: 200px;
  border-radius: 6px;
  position: absolute;
  left: max(calc(300px * 8), 100%);
  animation-name: scrollLeft;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.item-1 {
  animation-delay: calc(30s / 8 * (8 - 1) * -1);
}

.item-2 {
  animation-delay: calc(30s / 8 * (8 - 2) * -1);
}

.item-3 {
  animation-delay: calc(30s / 8 * (8 - 3) * -1);
}

.item-4 {
  animation-delay: calc(30s / 8 * (8 - 4) * -1);
}

.item-5 {
  animation-delay: calc(30s / 8 * (8 - 5) * -1);
}

.item-6 {
  animation-delay: calc(30s / 8 * (8 - 6) * -1);
}

.item-7 {
  animation-delay: calc(30s / 8 * (8 - 7) * -1);
}

.item-8 {
  animation-delay: calc(30s / 8 * (8 - 8) * -1);
}
